import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Academia BA',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '280px',
  map_hide: true,
  catalogTalents: true,
};

export default config;
